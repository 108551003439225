import { useRef } from 'react';

import Logo from '@/components/svgs/logo.svg';
import AnimatedLink from '@/components/AnimatedLink/AnimatedLink';
import SocialLinks from '@/components/SocialLinks/SocialLinks';

import routes from '@/data/routes';

function Nav() {
  const mainRef = useRef(null);

  return (
    <nav className="sticky top-0 w-full h-24 desktop:h-32 flex align-center z-10" ref={mainRef}>
      <div className="width-wrapper flex z-10">
        <ul className="flex grow items-center justify-start space-x-8">
          {Object.values(routes).map(({ path, title, isRoute }, index) => (
            <li key={index}>
              {path === '/' ? (
                <AnimatedLink link={path} isNextLink={isRoute} isExternal={false}>
                  <Logo className="w-10 h-10" />
                </AnimatedLink>
              ) : (
                <AnimatedLink
                  className="font-heading uppercase text-primary"
                  link={path}
                  text={title}
                  isNextLink={isRoute}
                  isExternal={false}
                />
              )}
            </li>
          ))}
        </ul>

        <SocialLinks className="justify-end" />
      </div>
      <div className="absolute -z-1 w-full h-[125%] bg-gradient-to-b via-[rgba(255,255,255,0.9)] from-white"></div>
    </nav>
  );
}

export default Nav;
