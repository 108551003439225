import React, { useEffect } from 'react';
import { AppProps } from 'next/app';
import { device, browser } from '@jam3/detect';
import classnames from 'classnames';

import 'normalize.css';
import '@/styles/global.scss';

import Layout from '@/components/Layout/Layout';
import { CopyProvider } from '@/hooks/use-copy';
import copyData from '@/data/copy';

import gsapInit from '@/utils/gsap-init';

const isBrowser = typeof window !== 'undefined';

if (isBrowser) {
  gsapInit();

  require('default-passive-events');
  require('focus-visible');
} else {
  React.useLayoutEffect = React.useEffect;
}

// This default export is required in a new `pages/_app.js` file.
function App({ Component, pageProps }: AppProps) {
  const { isUnsupported, ...componentProps } = pageProps;

  useEffect(() => {
    if (isBrowser) {
      // un-hide page once application kicked in and ready to perform animations
      document.documentElement.classList.remove('hide-page');

      if (process.env.NODE_ENV !== 'production' && window.location.href.indexOf('?nostat') === -1) {
        require('@jam3/stats')();
      }

      document.body.className = `${document.body.className} ${classnames(device.type, browser.name, {
        'touch-device': device.touch
      })}`.trim();
    }
  }, []);

  if (isUnsupported) {
    return <Component {...componentProps} />;
  }

  return (
    <CopyProvider value={copyData}>
      <Layout>
        <Component {...componentProps} />
      </Layout>
    </CopyProvider>
  );
}

export default App;
