import { memo, useEffect, useRef } from 'react';
import gsap from 'gsap';
import Bezier from 'bezier-easing';

function Loader() {
  const div1 = useRef(null);
  const div2 = useRef(null);

  useEffect(() => {
    const inEase = Bezier(0.83, 0, 0.17, 1);
    gsap.to(div1.current, { scaleX: 0, duration: 0.5, ease: inEase, delay: 0.2 });
    gsap.to(div2.current, { scaleX: 0, duration: 0.5, ease: inEase, delay: 0.7 });
  }, []);

  return (
    <>
      <div
        ref={div2}
        className="z-20 fixed bg-primary w-full h-full scale-x-100 top-0 left-0 origin-right pointer-events-none"
      ></div>

      <div
        ref={div1}
        className="z-20 fixed bg-secondary w-full h-full scale-x-100 top-0 left-0 origin-right pointer-events-none"
      ></div>
    </>
  );
}

export default memo(Loader);
