export type Route = {
  readonly path: string;
  readonly title: string;
  readonly isRoute: boolean;
};

export interface Routes {
  readonly Home: Route;
  readonly Work: Route;
  readonly Education: Route;
}

const routes: Routes = {
  Home: {
    path: '/',
    title: 'Home',
    isRoute: true
  },
  Work: {
    path: '/#work',
    title: 'Work',
    isRoute: false
  },
  Education: {
    path: '/#education',
    title: 'Education',
    isRoute: false
  }
};

export default routes;
