import { memo } from 'react';

import SocialLinks from '@/components/SocialLinks/SocialLinks';

import sanitizer from '@/utils/sanitizer';

const FooterItems = [`${new Date().getFullYear()}`, `Laura Amador`, `Toronto, Canada`];

function Footer() {
  return (
    <footer className="w-full mb-48 sticky top-[100vh]">
      <div className="width-wrapper text-center">
        <SocialLinks className="justify-center mb-8" />
        <ul className="flex justify-center divide-x text-offText">
          {FooterItems.map((item, index) => (
            <li className="px-4" key={index} dangerouslySetInnerHTML={{ __html: sanitizer(item) }} />
          ))}
        </ul>
      </div>
    </footer>
  );
}

export default memo(Footer);
