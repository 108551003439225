import { createContext, useContext } from 'react';
import copy from '@/data/copy';

const context = createContext(copy);

export const CopyProvider = context.Provider;

export function useCopy() {
  return useContext(context);
}
