import { copyGlobal } from './global';

import { copyEducation } from './education';
import { copyWork } from './work';
import { copyVolunteering } from './volunteering';

export default {
  global: copyGlobal,
  education: copyEducation,
  work: copyWork,
  volunteering: copyVolunteering
};
