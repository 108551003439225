import { memo } from 'react';
import classnames from 'classnames';

// import FacebookLogo from '@/components/svgs/facebook.svg';
// import TwitterLogo from '@/components/svgs/twitter.svg';
import InstagramLogo from '@/components/svgs/instagram.svg';
import LinkedinLogo from '@/components/svgs/linkedin.svg';

export type Props = {
  className?: string;
};

const LINKS = [
  // { href: 'https://jam3.com', label: 'Jam3', file: FacebookLogo },
  { href: 'https://www.linkedin.com/in/laura-amador-b6040a122', label: 'GitHub', file: LinkedinLogo },
  { href: 'https://www.instagram.com/laura.3141', label: 'GitHub', file: InstagramLogo }
  // { href: 'https://github.com/jam3', label: 'GitHub', file: TwitterLogo }
].map((link) => ({
  ...link,
  key: `nav-link-${link.href}-${link.label}`
}));

function SocialLinks({ className }: Props) {
  return (
    <ul className={classnames(className, 'flex grow items-center space-x-4')}>
      {LINKS.map(({ href, label, file }, index) => {
        const Icon = file;

        return (
          <li key={index}>
            <a
              className="w-8 h-8 block relative hover:text-primary transition-colors transition-transform duration-500 hover:scale-105"
              href={href}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={label}
            >
              <Icon className="absolute w-full h-full" />
            </a>
          </li>
        );
      })}
    </ul>
  );
}

export default memo(SocialLinks);
