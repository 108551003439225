import gsap from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import Bezier from 'bezier-easing';

function gsapInit() {
  const defaultEase = Bezier(0.2, 1, 0.8, 1.0);

  gsap.registerPlugin(ScrollTrigger);
  gsap.defaults({ ease: defaultEase, duration: 0.333 });
  gsap.config({ nullTargetWarn: false });
}

export default gsapInit;
