import { memo } from 'react';
import Link from 'next/link';
import classnames from 'classnames';

import styles from './AnimatedLink.module.scss';

export type Props = {
  className?: string;
  text?: string;
  link: string;
  isExternal?: boolean;
  isNextLink?: boolean;
  withIcon?: boolean;
  children?: React.ReactNode;
};

function AnimatedLink({
  className,
  text = '',
  link,
  isExternal = true,
  isNextLink = false,
  withIcon = false,
  children
}: Props) {
  const classNames = classnames(styles.AnimatedLink, ' text-primary no-underline relative inline-block', className);
  const otherProps = isExternal && !isNextLink ? { target: '_blank', rel: 'noopener noreferrer' } : {};

  return isNextLink ? (
    <Link href={link}>
      <a className={classNames} {...otherProps}>
        {children ? children : <span>{text}</span>}
      </a>
    </Link>
  ) : (
    <a className={classNames} href={link} {...otherProps}>
      {withIcon && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 text-text inline-block absolute -right-[1em]"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z"
            clipRule="evenodd"
          />
        </svg>
      )}
      {children ? children : <span>{text} </span>}
    </a>
  );
}

export default memo(AnimatedLink);
