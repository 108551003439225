import { memo, PropsWithChildren } from 'react';

import Nav from '@/components/Nav/Nav';
import Footer from '@/components/Footer/Footer';
import Loader from '@/components/Loader/Loader';
// import { GtmScript } from '@/utils/analytics';

export type Props = PropsWithChildren<{}>;

function Layout({ children }: Props) {
  return (
    <>
      {/* <GtmScript /> */}
      <Nav />
      {children}

      <Footer />
      <Loader />
    </>
  );
}

export default memo(Layout);
